'use client';

import { Button } from '@mantine/core';
import React, { cloneElement, ReactElement, useState } from 'react';

interface SSOButtonProps {
  icon: ReactElement<{ width?: number | string; height?: number | string }>;
  children: React.ReactNode;
  href: string;
}
export default function SSOButton({ icon, children, href }: SSOButtonProps) {
  const [isLoading, setIsLoading] = useState(false);
  const provider = React.isValidElement(icon) ? cloneElement(icon, { width: 21, height: 21 }) : icon;
  return (
    <Button
      leftSection={provider}
      fullWidth
      variant='outline'
      component='a'
      href={href}
      loading={isLoading}
      data-disabled={isLoading}
      onClick={() => setIsLoading(true)}
    >
      {children}
    </Button>
  );
}
