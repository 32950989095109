'use client';

import { useFormStatus } from 'react-dom';
import { PropsWithChildren } from 'react';
import { Button } from '@mantine/core';

export default function SubmitButton({
  children,
  disabled = false,
  isLoading = false,
}: PropsWithChildren & {
  isLoading?: boolean;
  disabled?: boolean;
}) {
  const { pending } = useFormStatus();
  const loading = pending || isLoading;
  const isDisabled = disabled || loading;

  return (
    <Button type='submit' aria-disabled={isDisabled} disabled={isDisabled} loading={loading}>
      {children}
    </Button>
  );
}
